// Initialize first
@import '../init';

// Import files
@import 'help';

// Add your style here

.help {
  .hero {
    .container {
      padding-top: toRem(166);
      padding-bottom: toRem(80);
      display: flex;
      flex-direction: column;
      gap: toRem(64);
      align-items: center;

      @include media-breakpoint-down(md) {
        padding: toRem(64) 0;
        padding-top: toRem(136);
      }

      @include media-breakpoint-down(sm) {
        padding-inline: toRem(20);
        gap: toRem(48);
      }
    }

    &__header {
      max-width: 596px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(24);

      h1 {
        color: $color-white;
      }

      p {
        max-width: 460px;
        color: $color-lighter-grey;
      }
    }

    &__content {
      --bs-gutter-x: 24px;
      --bs-gutter-y: 24px;
      margin: toRem(-24) toRem(-12);

      .hero-card {
        padding: toRem(32);
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: $shadow-sm;
        display: flex;
        flex-direction: column;
        gap: toRem(24);

        @include media-breakpoint-down(lg) {
          padding: toRem(24);
        }

        .hero-card-text {
          display: flex;
          flex-direction: column;
          gap: toRem(12);

          h4 {
            color: $color-dark-blue;
          }

          p {
            color: $color-grey;
          }
        }

        .hero-card-button {
          width: 100%;
          margin-top: toRem(8);

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
